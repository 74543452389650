const initialState = {
  mainSlider: [],
  mainNumbers: [],
  mainPopular: [],
  mainAdv: [],
  lastNews: [],
  mainNews: [],
  mainProductReviews: [],
  mainPortfolio: [],
  mainReviews: [],
  team: [],
  videos: [],
  questions: [],
  questionSections: [],
  reviews: [],
  shopReviews: [],
  certificates: [],
  jobs: [],
  catalogBanners: [],
  about: [],
  settings: {},
};

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MAIN_SLIDER":
      return { ...state, mainSlider: action.payload };
    case "FETCH_MAIN_NUMBERS":
      return { ...state, mainNumbers: action.payload };
    case "FETCH_MAIN_POPULAR":
      return { ...state, mainPopular: action.payload };
    case "FETCH_MAIN_ADV":
      return { ...state, mainAdv: action.payload };
    case "FETCH_LAST_NEWS":
      return { ...state, lastNews: action.payload };
    case "FETCH_MAIN_NEWS":
      return { ...state, mainNews: action.payload };
    case "FETCH_MAIN_PRODUCT_REVIEWS":
      return { ...state, mainProductReviews: action.payload };
    case "FETCH_MAIN_PORTFOLIO":
      return { ...state, mainPortfolio: action.payload };
    case "FETCH_MAIN_REVIEWS":
      return { ...state, mainReviews: action.payload };
    case "FETCH_SHOP_REVIEWS":
      return { ...state, shopReviews: action.payload };
    case "FETCH_TEAM":
      return { ...state, team: action.payload };
    case "FETCH_VIDEOS":
      return { ...state, videos: action.payload };
    case "FETCH_QUESTIONS":
      return { ...state, questions: action.payload };
    case "FETCH_QUESTION_SECTIONS":
      return { ...state, questionSections: action.payload };
    case "FETCH_REVIEWS":
      return { ...state, reviews: action.payload };
    case "FETCH_CERTIFICATES":
      return { ...state, certificates: action.payload };
    case "FETCH_JOBS":
      return { ...state, jobs: action.payload };
    case "FETCH_SERVICES":
      return { ...state, services: action.payload };
    case "FETCH_CATALOG_BANNERS":
      return { ...state, catalogBanners: action.payload };
    case "FETCH_ABOUT":
      return { ...state, about: action.payload };
    case "FETCH_SETTINGS":
      return { ...state, settings: action.payload };
    default:
      return state;
  }
};
