const initialState = {
  services: [],
  detail: {},
  detailInstall: {},
  detailInstallMarks: {},
};

export const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SERVICES":
      return { ...state, services: action.payload };
    case "FETCH_SERIVCE":
      return { ...state, detail: action.payload };
    case "FETCH_SERIVCE_INSTALL":
      return { ...state, detailInstall: action.payload };
    case "FETCH_SERIVCE_INSTALL_MARKS":
      return { ...state, detailInstallMarks: action.payload };
    default:
      return state;
  }
};
