// import {marks} from "../../cache/marks";

const initialState = {
  marks: [],
  topMarks: [],
  models: [],
  types: [],
};

export const modelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_MARKS":
      return { ...state, marks: action.payload ?? [] };
    case "FETCH_TOP_MARKS":
      return { ...state, topMarks: action.payload ?? [] };
    case "FETCH_MODELS":
      return { ...state, models: action.payload ?? [] };
    case "FETCH_TYPES":
      return { ...state, types: action.payload ?? [] };
    default:
      return state;
  }
};
