const initialState = {
  params: {},
  filter: {},
  isNeedFilter: true,
  isValidFilter: true,
};

export const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FILTER":
      return { ...state, filter: action.payload };
    case "SET_FILTER_PARAMS":
      return { ...state, params: action.payload };
    case "SET_ID_NEED_FILTER":
      return { ...state, isNeedFilter: action.payload };
    case "SET_IS_VALID_FILTER":
      return { ...state, isValidFilter: action.payload };
    default:
      return state;
  }
};
