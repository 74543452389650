// @ts-ignore
import { HYDRATE } from "next-redux-wrapper";
// @ts-ignore
import { combineReducers } from "redux";

import { baltexReducer } from "~/redux/reducers/baltexReducer";
import { paramsReducer } from "~/redux/reducers/paramsReducer";

import { brandsReducer } from "./brandsReducer";
import { breadcrumbsReducer } from "./breadcrumbsReducer";
import { cartReducer } from "./cartReducer";
import { contactsReducer } from "./contactsReducer";
import { contentReducer } from "./contentReducer";
import { filterReducer } from "./filterReducer";
import { locationReducer } from "./locationReducer";
import { metaReducer } from "./metaReducer";
import { modelsReducer } from "./modelsReducer";
import { newsReducer } from "./newsReducer";
import { pageStateReducer } from "./pageStateReducer";
import { popupReducer } from "./popupReducer";
import { productReducer } from "./productReducer";
import { propsReducer } from "./propsReducer";
import { sectionReducer } from "./sectionReducer";
import { sectionsReducer } from "./sectionsReducer";
import { servicesReducer } from "./servicesReducer";
import { userReducer } from "./userReducer";

const rootReducer = combineReducers({
  sections: sectionsReducer,
  section: sectionReducer,
  product: productReducer,
  models: modelsReducer,
  breadcrumbs: breadcrumbsReducer,
  popup: popupReducer,
  user: userReducer,
  cart: cartReducer,
  location: locationReducer,
  contacts: contactsReducer,
  filter: filterReducer,
  content: contentReducer,
  news: newsReducer,
  services: servicesReducer,
  brands: brandsReducer,
  pageState: pageStateReducer,
  meta: metaReducer,
  props: propsReducer,
  baltex: baltexReducer,
  params: paramsReducer,
});

export const reducer = (state: any, action: any) => {
  if (action.type === HYDRATE) {
    const nextState = {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
    };
    // nextState.cart = state.cart;
    // nextState.contacts = state.contacts;
    // nextState.shops = state.shops;
    // nextState.location = state.location;
    // nextState.models = state.models;
    nextState.content.catalogBanners = state.content.catalogBanners;
    nextState.user = state.user;
    return nextState;
  }
  return rootReducer(state, action);
};
