const initialState = {
  cartData: {},
  basket: {},
  paymentList: [],
  deliveryList: [],
  deliveryListAdditional: [],
  edostData: {},
  params: {},
  isBasketLoading: false,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export const cartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_CART_DATA":
      return { ...state, cartData: action.payload };
    case "SET_CART_PARAMS":
      return { ...state, params: action.payload };
    case "SET_CART_PARAM":
      return { ...state, params: { ...state.params, ...action.payload } };
    case "SET_BASKET":
      return { ...state, basket: action.payload };
    case "SET_BASKET_LOADING":
      return { ...state, isBasketLoading: action.payload };
    case "SET_DELIVERY_LIST":
      return { ...state, deliveryList: action.payload };
    case "SET_DELIVERY_LIST_ADDITIONAL":
      return { ...state, deliveryListAdditional: action.payload };
    case "SET_PAYMENT_LIST":
      return { ...state, paymentList: action.payload };
    default:
      return state;
  }
};
