const initialState = {
  title: "",
  h1: "",
  description: "",
  content: "",
  yandexTag: "",
  googleTag: "",
};

export const metaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TITLE":
      return { ...state, title: action.payload };
    case "SET_H1":
      return { ...state, h1: action.payload };
    case "SET_DESCRIPTION":
      return { ...state, description: action.payload };
    case "SET_CONTENT":
      return { ...state, content: action.payload };
    case "SET_Y_TAG":
      return { ...state, yandexTag: action.payload };
    case "SET_G_TAG":
      return { ...state, googleTag: action.payload };
    default:
      return state;
  }
};
