const initialState = {
  isShowTopSections: false,
  isShowMobileMenu: false,
};

export const pageStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_TOP_SECTIONS":
      return { ...state, isShowTopSections: action.payload };
    case "SET_SHOW_MOBILE_MENU":
      return { ...state, isShowMobileMenu: action.payload };
    default:
      return state;
  }
};
