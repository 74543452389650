import "../styles/globals.css";
import "nprogress/nprogress.css";
import "../styles/font/golostext.css";
import "../styles/font/gilroy.css";
import "../styles/font/manrope.css";

import Router from "next/router";
import NProgress from "nprogress";

import { wrapper } from "../redux";

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }: any) {
  return <Component {...pageProps} />;
}

export default wrapper.withRedux(MyApp);
