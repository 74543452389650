const initialState = {
  user: "",
  autos: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_AUTOS":
      return { ...state, autos: action.payload };
    default:
      return state;
  }
};
