// @ts-ignore
import { composeWithDevTools } from "@redux-devtools/extension";
import { createWrapper } from "next-redux-wrapper";
// @ts-ignore
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

import { reducer } from "./reducers";

// create a makeStore function
const makeStore = (context: any) =>
  createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

// export an assembled wrapper
export const wrapper = createWrapper(makeStore, { debug: false });
