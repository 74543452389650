const initialState = {
  props: {},
};

export const propsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_PROPS":
      return { ...state, props: action.payload };
    default:
      return state;
  }
};
