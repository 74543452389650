const initialState = {
  products: [],
  count: 0,
  sort: [
    {
      name: "Популярности",
      type: "VIEWS",
    },
    {
      name: "Цене",
      type: "PRICE",
    },
    {
      name: "Новизне",
      type: "ID",
    },
    {
      name: "Скидке",
      type: "DISCOUNT",
    },
  ],
  size: [24, 36, 48],
  meta: {},
  params: {
    pageSize: 25,
    pageNum: 1,
    sortBy: "PRICE",
    sortOrder: "asc",
    sectionId: 0,
  },
  qestionsAndReviewsCount: {},
};

export const sectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SECTION":
      return { ...state, products: [...state.products, ...action.payload] };
    case "SET_COUNT":
      return { ...state, count: action.payload };
    case "SET_SECTION_ID":
      return {
        ...state,
        params: { ...state.params, sectionId: action.payload },
      };
    case "SET_PAGE_NUM":
      return { ...state, params: { ...state.params, pageNum: action.payload } };
    case "SET_PAGE_SIZE":
      return {
        ...state,
        params: { ...state.params, pageSize: action.payload },
      };
    case "SET_SORT_BY":
      return { ...state, params: { ...state.params, sortBy: action.payload } };
    case "SET_SORT_ORDER":
      return {
        ...state,
        params: { ...state.params, sortOrder: action.payload },
      };
    case "SET_META":
      return { ...state, meta: action.payload };
    case "FETCH_ALL_REVIEWS_AND_QUESTIONS":
      return { ...state, qestionsAndReviewsCount: action.payload };
    default:
      return state;
  }
};
