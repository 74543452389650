const initialState = {
  sections: [],
};

export const sectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_SECTIONS":
      return { ...state, sections: action.payload };
    default:
      return state;
  }
};
