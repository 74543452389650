export enum SiteTypes {
  Farkop,
  Baltex,
  Landing,
}

const initialState = {
  siteType: SiteTypes.Farkop,
  host: "",
};

export const paramsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SITE_TYPE":
      return { ...state, siteType: action.payload };
    case "SET_HOST":
      return { ...state, host: action.payload };
    default:
      return state;
  }
};
