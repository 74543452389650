export const popupTypes = {
  location: "location",
  locationNew: "locationNew",
  auth: "auth",
  backCall: "backCall",
  cart: "cart",
  buyOneClick: "buyOneClick",
  findLowPrice: "findLowPrice",
  fastView: "fastView",
  none: "none",
  addReview: "addReview",
  addQuestion: "addQuestion",
  addComment: "addComment",
  serviceOrder: "serviceOrder",
  serviceQuestion: "serviceQuestion",
  video: "video",
  subscribeProduct: "subscribeProduct",
  slider: "slider",
  productVideo: "productVideo",
  productReviews: "productReviews",
  productQuestions: "productQuestions",
  productPoints: "productPoints",
  fastViewService: "fastViewService",
  orderInstall: "orderInstall",
  scheme: "scheme",
  vacancy: "vacancy",
  feedBack: "feedBack",
  videoConsultation: "videoConsultation",
  message: "message",
  like: "like",
  dislike: "dislike",
  assortment: "assortment",
  videoFull: "videoFull",
};

const initialState = {
  visibleType: popupTypes.none,
  data: {},
};

export const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_POPUP":
      return { ...state, visibleType: action.payload };
    case "SET_POPUP_DATA":
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
