const initialState = {
  news: [],
  sections: [],
  detail: {},
  params: {
    pageSize: 28,
    pageNum: 1,
    sectionId: 0,
  },
};

export const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_NEWS":
      return { ...state, news: action.payload };
    case "SET_NEWS_COUNT":
      return { ...state, count: action.payload };
    case "FETCH_NEWS_SECTIONS":
      return { ...state, sections: action.payload };
    case "SET_NEWS_SECTION_ID":
      return {
        ...state,
        params: { ...state.params, sectionId: action.payload },
      };
    case "SET_NEWS_PAGEN":
      return { ...state, params: { ...state.params, pageNum: action.payload } };
    case "FETCH_NEWS_DETAIL":
      return { ...state, detail: action.payload };
    default:
      return state;
  }
};
