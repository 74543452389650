const initialState = {
  breadcrumbs: [
    {
      link: "/",
      name: "Главная",
    },
  ],
};

export const breadcrumbsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_BREADCRUMB":
      return { breadcrumbs: [...state.breadcrumbs, action.payload] };
    default:
      return state;
  }
};
